.galleryComponent{
  margin: 50px auto 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.galleryText{
  width: 60%;
  margin:auto;
  text-align: justify;
}

.galleryYear{
  margin-bottom: 20px;
}

@media screen and (max-width: 1500px) {
  .galleryComponent{
    grid-template-columns: 1fr;
  }
  .galleryText{
    width: 100%;
    padding: 10px;
  }
  .overviewBlock-portraitright{
    margin: 15px 0 0 0;
  }
  .overviewBlock-portraitleft{
    margin: 15px 0 0 0;
  }
}
