@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;900&display=swap');
*,body{
  font-family: 'Montserrat', sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: #222;
}

img{
  max-width: 100%;
}

.container{
  max-width: 85%;
  margin:auto;
}
