/* Navbar */
.nav-block {
  display: flex;
  list-style: none;
}

.home_link{
  border-radius: 5px;
  padding: 5px 10px;
  background-color: white;
}


.nav-wrapper{
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  padding: 0 10%;
  align-items: center;
}

.nav-block a{
  position: relative;
  margin-left: 30px;
  font-size: 1.2rem;
  margin-top: 10px;
  padding: 5px 10px;
}

.nav-block a{
  text-decoration: none;
  display: inline-block;
  color: black;
  font-weight:bold;
  cursor: pointer;
  background-color: white;
  opacity: .8;
  border-radius: 5px;
}

.link {
  display: inline-block;
  color: #000;
  text-decoration: none;
}

.nav-block a::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #000;
  transition: width .3s;
}

.nav-block a:hover::after {
  width: 100%;
  transition: width .3s;
}

.nav-block a.active{
  position: relative;
}

.nav-block a.active::after{
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background: #000;
}

@media screen and (max-width: 450px) {
  .nav-wrapper a{
    font-size: .62rem;
    margin: 10px 10px 0 0;
    padding: 3px 5px 2px;
  }

  .nav-wrapper{
    padding: 0 1.5%;
  }


}
