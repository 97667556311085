@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;900&display=swap);
*,body{
  font-family: 'Montserrat', sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: #222;
}

img{
  max-width: 100%;
}

.container{
  max-width: 85%;
  margin:auto;
}


/* Navbar */
.nav-block {
  display: flex;
  list-style: none;
}

.home_link{
  border-radius: 5px;
  padding: 5px 10px;
  background-color: white;
}


.nav-wrapper{
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  padding: 0 10%;
  align-items: center;
}

.nav-block a{
  position: relative;
  margin-left: 30px;
  font-size: 1.2rem;
  margin-top: 10px;
  padding: 5px 10px;
}

.nav-block a{
  text-decoration: none;
  display: inline-block;
  color: black;
  font-weight:bold;
  cursor: pointer;
  background-color: white;
  opacity: .8;
  border-radius: 5px;
}

.link {
  display: inline-block;
  color: #000;
  text-decoration: none;
}

.nav-block a::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #000;
  transition: width .3s;
}

.nav-block a:hover::after {
  width: 100%;
  transition: width .3s;
}

.nav-block a.active{
  position: relative;
}

.nav-block a.active::after{
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background: #000;
}

@media screen and (max-width: 450px) {
  .nav-wrapper a{
    font-size: .62rem;
    margin: 10px 10px 0 0;
    padding: 3px 5px 2px;
  }

  .nav-wrapper{
    padding: 0 1.5%;
  }


}

/* Home */
.home{
  max-width: 85%;
  margin: auto;
  padding: 4% 0;
}

.main-block {
  display: grid;
  grid-template-columns: 1fr 2fr;
  max-width: 1450px;
  margin: 50px auto;
}

.content-block{
  max-width: 600px;
  font-size: 1.5rem;
  display: grid;
  align-items: center;
  position: relative;
}

.content-block p{
  margin-top: 30px;
}

.cb{
  width: 115%;
  height: 50%;
  padding: 10px;
  position: absolute;
  top: 25%;
  left: 50%;
}

.cb h1{
  font-size: 3.3vw;
  transition: all .5s ease;
}

.cb p{
  font-size: 1.5vw;
  transition: all .5s ease;
  transition-delay: .5s;
  text-align: justify;
}


.cb span{
  color: #cf90a9;
}

.image-block{
  max-width: 850px;
  margin-left: auto;
  transition: all .5s ease;
  transition-delay: 1s;
  z-index: -1;
}

/* Media query */
@media screen and (max-width: 1500px) {
  .cb{
    margin-left: -14%;
  }
}

@media screen and (max-width: 450px) {
  .home{
    max-width: 100%;
    width: 100%;
  }

  .main-block{
    display: block;
  }

  .cb{
    position: relative;
    background-color: white;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .content-block{
    display: block;
    padding: 10px 20px 30px 20px;
    text-align:left;
  }

  .content-block h1{
    font-size: 8vw;
    padding-right:20px;
  }

  .content-block p{
    margin: 10px 0;
    font-size: 5vw;
    padding-right:20px;
  }

  .image-block{
    overflow: hidden;
  }

  .image-block div{
    width: 120%;
  }



}

.projectBlock{
  display: grid;
  margin: 0 auto 150px;
  grid-auto-columns: minmax(20%,auto);
  align-items: center;
  grid-gap: 30px;
}

.projectBlock-left{
  grid-template-areas: "image image overview";
  text-align: right;
}

.projectBlock-right{
  grid-template-areas: "overview image image";
  margin: 0 auto 150px;
  text-align: left;
}

.projectBlock-portraitleft{
  grid-template-areas: "overview image";
  grid-auto-columns: minmax(50%,auto);
  text-align: right;
}

.projectBlock-portraitright{
  grid-template-areas: "overview image";
  grid-auto-columns: minmax(50%,auto);
  text-align: left;
}

/* Painting Block */
.paintingBlock{
  grid-area: image;
}


/* overview Block */
.overviewBlock{
  grid-area: overview;
}

.overviewBlock-portraitright{
  width: 150px;
}

.dimensions{
  color: silver;
  margin-top: 10px;
}

.work-section-text, .dimensions, .paintingname-block h3{
  font-size: 1rem;
}

.react-transform-component{
  /* -webkit-box-shadow: 10px 11px 33px 0px rgba(0,0,0,0.43);
  -moz-box-shadow: 10px 11px 33px 0px rgba(0,0,0,0.43);
  box-shadow: 10px 11px 33px 0px rgba(0,0,0,0.43); */
  cursor: -webkit-grab;
  cursor: grab;
}
.enquiry-box{
  margin-top: 30px;
}
.enquiry{
  cursor:pointer;
  font-size: 12px;
  border-bottom:1px solid black;
}

@media screen and (max-width: 1500px) {
  .projectBlock{
    grid-gap:40px;
  }
}

@media screen and (max-width: 450px) {
  .projectBlock{
    display: flex;
    flex-direction:column;
    margin: 0 0 30px 0;
    text-align: left;
    align-items: baseline;
  }
  .overviewBlock{
    margin-top: 15px;
  }
}

.galleryComponent{
  margin: 50px auto 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.galleryText{
  width: 60%;
  margin:auto;
  text-align: justify;
}

.galleryYear{
  margin-bottom: 20px;
}

@media screen and (max-width: 1500px) {
  .galleryComponent{
    grid-template-columns: 1fr;
  }
  .galleryText{
    width: 100%;
    padding: 10px;
  }
  .overviewBlock-portraitright{
    margin: 15px 0 0 0;
  }
  .overviewBlock-portraitleft{
    margin: 15px 0 0 0;
  }
}

/* Work Section */

.work-section{
  margin: 20px auto;
}

.selected-work{
  margin-bottom: 100px;
  font-size: 5rem;
  border-bottom: 2px solid #222;
  display: inline-block;
}

@media screen and (max-width: 1500px) {
  .selected-work{
    font-size: 3rem;
  }
}

@media screen and (max-width: 450px) {
  .work-section{
    margin: 0 auto;
  }
  .selected-work{
    font-size: 5.5vw;
    margin-bottom: 50px;
  }
}

input[type="text"],input[type="email"], textarea[type="text"] {
  background-color :#222;
}

.input-block{
  position:relative;
  overflow: hidden;
}

.form input{
  width: 100%;
  height: 100%;
  padding-top:20px;
  border: none;
  outline: none;
  color: silver;
  padding-bottom: 5px;
}

.form-label{
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: none;
  border-bottom: 1px solid silver;
  width: 100%;
  height: 100%;
}

.form-label::after{
  content:"";
  position: absolute;
  bottom: -1px;
  left: 0;
  border-bottom: 2px solid #fafafa;
  width: 100%;
  height: 100%;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: all 0.3s ease;
}

.label-content{
  position: absolute;
  bottom: 5px;
  left: 0;
  transition: all 0.3s ease;
  color: silver;
}

.form input:focus + .form-label .label-content,
.form input:valid + .form-label .label-content {
  -webkit-transform: translateY(-120%);
          transform: translateY(-120%);
  font-size: 13px;
  color: #fafafa;
}

.form input[value]:not([value=""]) + .form-label .label-content{
  -webkit-transform: translateY(-120%);
          transform: translateY(-120%);
  font-size: 13px;
  color: #fafafa;
}


.form input:focus + .form-label::after,
.form input:valid + .form-label::after{
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.form input[value]:not([value=""]) + .form-label .label-content::after{
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.email {
  width: 50%;
}

/* Contact */


#contact{
  display: grid;
  align-items: center;
  background-color: #222;
  padding: 30px 0;
  min-height: 100vh;
}



.connect h1{
  font-size: 3rem;
  padding: 20px 0 0 0;
  color: #f1f1f1;
  border-bottom: 2px solid #f1f1f1;
  display: inline-block;
}
.connect{
  margin: 20px 0 60px 0;
  display: flex;
  justify-content: space-between;
  width: 70%;
}

.social-media{
  width: 120px;
  display:flex;
  justify-content:space-between;
  align-items: center;
}

.icon{
  width: 50px;
}

.name-block{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.form-block form{
  display: grid;
  width: 70%;
  grid-gap: 30px;
}


textarea[type="text"]{
  width: 100%;
  height: 250px;
  font-size: 1.2rem;
  color: silver;
  padding: 10px;
  margin-top: 30px;
  transition: all 0.6s ease;
}

textarea[type="text"]:focus{
  outline-color: #fafafa;
  box-shadow: 0px 0px 10px 1px #fafafa;
}

#button {
  padding: 10px 20px;
  background: none;
  color: #fafafa;
  margin-left: auto;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
  border-color: #fafafa;
}

#button:hover {
  background: #fafafa;
  color: #222;
}

#msg_sent{
  padding: 10px 20px;
  color: white;
  height: 10px;
}

@media screen and (max-width: 1500px) {
  .connect h1{
    font-size: 2rem;
  }
}

@media screen and (max-width: 450px) {
  .connect{
    width: 100%;
  }
  .connect h1{
    font-size: 6.2vw;
    padding: 0;
  }
  .name-block{
    grid-template-columns: 1fr;
  }
  .social-media{
    width: 60px;
  }
  .social-media a{
    width: 25px;
  }
  .form-block form{
    width: 100%;
  }
  .email{
    width: 100%;
  }
  #button {
    font-size: .75rem;
  }

}

