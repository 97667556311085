input[type="text"],input[type="email"], textarea[type="text"] {
  background-color :#222;
}

.input-block{
  position:relative;
  overflow: hidden;
}

.form input{
  width: 100%;
  height: 100%;
  padding-top:20px;
  border: none;
  outline: none;
  color: silver;
  padding-bottom: 5px;
}

.form-label{
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: none;
  border-bottom: 1px solid silver;
  width: 100%;
  height: 100%;
}

.form-label::after{
  content:"";
  position: absolute;
  bottom: -1px;
  left: 0;
  border-bottom: 2px solid #fafafa;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  transition: all 0.3s ease;
}

.label-content{
  position: absolute;
  bottom: 5px;
  left: 0;
  transition: all 0.3s ease;
  color: silver;
}

.form input:focus + .form-label .label-content,
.form input:valid + .form-label .label-content {
  transform: translateY(-120%);
  font-size: 13px;
  color: #fafafa;
}

.form input[value]:not([value=""]) + .form-label .label-content{
  transform: translateY(-120%);
  font-size: 13px;
  color: #fafafa;
}


.form input:focus + .form-label::after,
.form input:valid + .form-label::after{
  transform: translateX(0);
}

.form input[value]:not([value=""]) + .form-label .label-content::after{
  transform: translateX(0);
}

.email {
  width: 50%;
}
