/* Contact */


#contact{
  display: grid;
  align-items: center;
  background-color: #222;
  padding: 30px 0;
  min-height: 100vh;
}



.connect h1{
  font-size: 3rem;
  padding: 20px 0 0 0;
  color: #f1f1f1;
  border-bottom: 2px solid #f1f1f1;
  display: inline-block;
}
.connect{
  margin: 20px 0 60px 0;
  display: flex;
  justify-content: space-between;
  width: 70%;
}

.social-media{
  width: 120px;
  display:flex;
  justify-content:space-between;
  align-items: center;
}

.icon{
  width: 50px;
}

.name-block{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.form-block form{
  display: grid;
  width: 70%;
  grid-gap: 30px;
}


textarea[type="text"]{
  width: 100%;
  height: 250px;
  font-size: 1.2rem;
  color: silver;
  padding: 10px;
  margin-top: 30px;
  transition: all 0.6s ease;
}

textarea[type="text"]:focus{
  outline-color: #fafafa;
  -webkit-box-shadow: 0px 0px 10px 1px #fafafa;
  -moz-box-shadow: 0px 0px 10px 1px #fafafa;
  box-shadow: 0px 0px 10px 1px #fafafa;
}

#button {
  padding: 10px 20px;
  background: none;
  color: #fafafa;
  margin-left: auto;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
  border-color: #fafafa;
}

#button:hover {
  background: #fafafa;
  color: #222;
}

#msg_sent{
  padding: 10px 20px;
  color: white;
  height: 10px;
}

@media screen and (max-width: 1500px) {
  .connect h1{
    font-size: 2rem;
  }
}

@media screen and (max-width: 450px) {
  .connect{
    width: 100%;
  }
  .connect h1{
    font-size: 6.2vw;
    padding: 0;
  }
  .name-block{
    grid-template-columns: 1fr;
  }
  .social-media{
    width: 60px;
  }
  .social-media a{
    width: 25px;
  }
  .form-block form{
    width: 100%;
  }
  .email{
    width: 100%;
  }
  #button {
    font-size: .75rem;
  }

}
