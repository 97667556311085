.projectBlock{
  display: grid;
  margin: 0 auto 150px;
  grid-auto-columns: minmax(20%,auto);
  align-items: center;
  grid-gap: 30px;
}

.projectBlock-left{
  grid-template-areas: "image image overview";
  text-align: right;
}

.projectBlock-right{
  grid-template-areas: "overview image image";
  margin: 0 auto 150px;
  text-align: left;
}

.projectBlock-portraitleft{
  grid-template-areas: "overview image";
  grid-auto-columns: minmax(50%,auto);
  text-align: right;
}

.projectBlock-portraitright{
  grid-template-areas: "overview image";
  grid-auto-columns: minmax(50%,auto);
  text-align: left;
}

/* Painting Block */
.paintingBlock{
  grid-area: image;
}


/* overview Block */
.overviewBlock{
  grid-area: overview;
}

.overviewBlock-portraitright{
  width: 150px;
}

.dimensions{
  color: silver;
  margin-top: 10px;
}

.work-section-text, .dimensions, .paintingname-block h3{
  font-size: 1rem;
}

.react-transform-component{
  /* -webkit-box-shadow: 10px 11px 33px 0px rgba(0,0,0,0.43);
  -moz-box-shadow: 10px 11px 33px 0px rgba(0,0,0,0.43);
  box-shadow: 10px 11px 33px 0px rgba(0,0,0,0.43); */
  cursor: grab;
}
.enquiry-box{
  margin-top: 30px;
}
.enquiry{
  cursor:pointer;
  font-size: 12px;
  border-bottom:1px solid black;
}

@media screen and (max-width: 1500px) {
  .projectBlock{
    grid-gap:40px;
  }
}

@media screen and (max-width: 450px) {
  .projectBlock{
    display: flex;
    flex-direction:column;
    margin: 0 0 30px 0;
    text-align: left;
    align-items: baseline;
  }
  .overviewBlock{
    margin-top: 15px;
  }
}
